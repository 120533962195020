import { PLUS_BACKEND, PLUS_FRONTEND } from '@/constants';
import { COURSE_LANDING_URL } from '@/constants/url';
import type { RecruitingStatus } from '@/hooks/react-query/common/round-status';
import type { CourseType } from '@/types';

import {
  BACKEND_CAREER_COACHING,
  FRONTEND_CAREER_COACHING,
  PLUS_AI,
  SHORT_TERM_SKILL_UP,
} from '../course';

export interface GnbRootMenuGroup {
  type: 'rootGroup';
  title: string;
  children?: Array<GnbMenuGroup | GnbMenuGroupLink>;
  link?: string;
}

export interface GnbMenuGroup {
  type: 'group';
  title: string;
  children: GnbMenuSubGroup[] | GnbMenuItem[];
  isNew?: boolean;
}

export interface GnbMenuGroupLink {
  type: 'groupLink';
  title: string;
  link: string;
  newTab?: boolean;
  isNew?: boolean;
}

export interface GnbMenuSubGroup {
  type: 'subGroup';
  title: string;
  menus: GnbMenuItem[];
}

export interface GnbMenuItem {
  type: 'item';
  title: string;
  link: string;
  courseType?: CourseType;
  newTab?: boolean;
  isNew?: boolean;
  mobileHidden?: boolean;
  disabled?: boolean;
}

type GnbMenu =
  | GnbRootMenuGroup
  | GnbMenuGroup
  | GnbMenuSubGroup
  | GnbMenuItem
  | GnbMenuGroupLink;

export const bootcampMenuGroups: Array<GnbMenuGroup | GnbMenuGroupLink> = [
  {
    type: 'group',
    title: '부트캠프',
    children: [
      {
        type: 'subGroup',
        title: '항해 플러스',
        menus: [
          {
            type: 'item',
            link: COURSE_LANDING_URL.HANGHAE_PLUS_AI,
            title: 'AI 코스',
            courseType: PLUS_AI,
          },
          {
            type: 'item',
            link: COURSE_LANDING_URL.HANGHAE_PLUS_BACKEND,
            title: '백엔드 코스',
            courseType: PLUS_BACKEND,
          },
          {
            type: 'item',
            link: COURSE_LANDING_URL.HANGHAE_PLUS_FRONTEND,
            title: '프론트엔드 코스',
            courseType: PLUS_FRONTEND,
          },
        ],
      },
      {
        type: 'subGroup',
        title: '단기 스킬업',
        menus: [
          {
            type: 'item',
            link: COURSE_LANDING_URL.SHORT_TERM_SKILL_UP,
            title: 'Redis 기반 대규모 트래픽 처리',
            courseType: SHORT_TERM_SKILL_UP,
            newTab: true,
          },
        ],
      },
    ],
  },
];

export const careerCoachingMenuGroups: Array<GnbMenuGroup | GnbMenuGroupLink> =
  [
    {
      type: 'group',
      title: '커리어 코칭',
      children: [
        {
          type: 'subGroup',
          title: '',
          menus: [
            {
              type: 'item',
              link: COURSE_LANDING_URL[BACKEND_CAREER_COACHING],
              title: '백엔드 커리어 코칭',
              courseType: BACKEND_CAREER_COACHING,
              newTab: true,
            },
            {
              type: 'item',
              link: COURSE_LANDING_URL[FRONTEND_CAREER_COACHING],
              title: '프론트엔드 커리어 코칭',
              courseType: FRONTEND_CAREER_COACHING,
              disabled: true,
            },
          ],
        },
      ],
    },
  ];

export const studyMenuGroups: Array<GnbMenuGroup | GnbMenuGroupLink> = [
  {
    type: 'group',
    title: '스터디',
    children: [
      {
        type: 'subGroup',
        title: '습관 형성 스터디',
        menus: [
          {
            type: 'item',
            link: COURSE_LANDING_URL['99CLUB_CODING_TEST'],
            title: '코딩 테스트 스터디',
            courseType: '99CLUB_CODING_TEST',
          },
        ],
      },
      {
        type: 'subGroup',
        title: '국비지원 강의 스터디',
        menus: [
          {
            type: 'item',
            link: 'https://99club-dockercicd.oopy.io/',
            title: 'Docker & CICD 실전배포',
            courseType: '99CLUB_DOCKER_STUDY',
            newTab: true,
          },
          {
            type: 'item',
            link: 'https://99club-algorithm.oopy.io/',
            title: '문제 해결력을 길러주는 알고리즘 첫걸음',
            courseType: '99CLUB_ALGORITHM_STUDY',
            newTab: true,
          },
          {
            type: 'item',
            link: 'https://99club-kubernetes.oopy.io/',
            title: 'Kubernetes : 클러스터 구축부터 장애상황 대응까지',
            courseType: '99CLUB_KUBERNETES_STUDY',
            newTab: true,
          },
          {
            type: 'item',
            link: 'https://99club-fe-advanced.oopy.io/',
            title: '프론트 실무 심화 : 테스트/배포 자동화/성능 최적화',
            courseType: '99CLUB_TDD_STUDY',
            newTab: true,
          },
          {
            type: 'item',
            link: 'https://99club-nextjs.oopy.io/',
            title: '최신 Next.js: App Router, 서버 액션, RSC & 최적화 정복',
            courseType: '99CLUB_NEXT_STUDY',
            newTab: true,
          },
        ],
      },
    ],
  },
];

export const GnbMenuList: GnbMenu[] = [
  {
    type: 'rootGroup',
    title: '부트캠프',
    children: bootcampMenuGroups,
  },
  {
    type: 'rootGroup',
    title: '커리어 코칭',
    children: careerCoachingMenuGroups,
  },
  {
    type: 'rootGroup',
    title: '스터디',
    children: studyMenuGroups,
  },
  { type: 'item', title: '커뮤니티', link: '/community', isNew: true },
  { type: 'item', title: '후기&스토리', link: '/blog', newTab: true },
];

export const MobileGnbDropdownMenuList: Array<GnbMenuGroup | GnbMenuGroupLink> =
  [
    ...bootcampMenuGroups,
    ...careerCoachingMenuGroups,
    ...studyMenuGroups,
    {
      type: 'groupLink',
      title: '커뮤니티',
      link: '/community',
      isNew: true,
    },
    {
      type: 'groupLink',
      title: '후기&스토리',
      link: '/blog',
      newTab: true,
    },
  ];

export const MobileGnbMenuList: GnbMenu[] = [
  { type: 'item', title: '나의 수강', link: '/users/my-page?tab=나의 수강' },
  { type: 'item', title: '결제 내역', link: '/users/my-page?tab=결제 내역' },
  { type: 'item', title: '계정 정보', link: '/users/my-page?tab=계정 정보' },
];

export function isGroup(item: GnbMenu): item is GnbMenuGroup {
  return item.type === 'group';
}

export function isSubGroup(item: GnbMenu): item is GnbMenuSubGroup {
  return item.type === 'subGroup';
}

export function isGroupLink(item: GnbMenu): item is GnbMenuGroupLink {
  return item.type === 'groupLink';
}

export function isItem(item: GnbMenu): item is GnbMenuItem {
  return item.type === 'item';
}

export const BADGE_TEXT_MAP: Record<RecruitingStatus, string> = {
  LOADING: '',
  RECRUITING: '모집 중',
  ALIM: '알림신청',
};
